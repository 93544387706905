.main_wrapper .enviornment_consideration li button {
    color: #000;
    width: 100%;
    padding: 20px;
}
.enviornment_consideration{
    border-bottom: none !important;
}
.color_div .enviornment_consideration li .nav-link.active {
    border: none !important;
    color: #ffffff !important;
    border-radius: 0;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(90deg,#1f9ed1 0%,rgb(0 149 209 / 45%) 100%);
}
