td.description {
  text-align: justify;
}

.table__overflow__style {
  overflow-x: auto;
  width: 100%;
  height: calc(100vh - 132px);
}

.industry_table {
  margin-top: 70px;
}

::-webkit-scrollbar {
  width: 5px !important;
}
::-webkit-scrollbar-track {
  background: #f9f7f7;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: #d8d3d3 !important;
  width: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
