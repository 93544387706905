@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
/* 
.form-control {
  box-shadow: none !important;
  display: flex !important;
  justify-content: space-between !important;
  color: #6c757d !important;
} */

/* .form-control:focus {
  border: 1px solid #05b49d !important;
} */

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .list-group {
  width: 249px;
}

#page-content-wrapper {
  min-width: 100vw;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}

span.logo_div img {
  width: 50px;
}

a {
  text-decoration: none !important;
}

.color_xl {
  width: 250px;
  background: #1f9ed1;
}

span.text_h4 h4 {
  color: white;
  padding-top: 5px;
  padding-left: 7px;
  font-size: 26px;
  font-weight: 600;
}

.navclass {
  height: 100px;
}

.deahbord {
  padding-left: 20px;
}

.deahbord h4 {
  width: 96px;
  height: 26px;
  left: 300px;
  top: 37px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 37px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #4f5367;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #c5cbe2;
  font-size: 14px;
}

.one input.form-control {
  width: 350px;
  height: 42px;
  left: 830px;
  top: 29px;
  border: 1.4px solid #ebefff;
  box-sizing: border-box;
  border-radius: 100px;
}

.d-flex .background {
  width: calc(100% - 250px);
  padding: 8px;
  left: 250px;
  top: 0;
  position: fixed;
  z-index: 1045;
  background: #fff;
}

.logo_text img {
  width: 50%;
  padding: 8px;
}

.logo_text {
  text-align: center;
}

.one {
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
}

/* .form-control::placeholder {
  color: #c5cbe2;
  font-size: 14px;
} */

.image--coverq {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.text_down {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.elly {
  color: #333333;
}

.home_drop {
  letter-spacing: 0em;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  color: #1f9ed1 !important;
}

p.text_p {
  letter-spacing: 0em;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #888888;
}

.home_drop:hover {
  color: #23317c;
}

.nav_icon {
  padding-right: 8px;
}

img.imageq_icon {
  width: 22px;
  margin-bottom: 4px;
}

.nop {
  border: none;
  font-size: 13px;
}

ul.home_icon_img li {
  list-style: none;
}

ul.home_icon_img li a {
  margin-bottom: 20px;
}

ul.home_icon_img li a:hover {
  background: linear-gradient(91.53deg, #233076 13.86%, #41c7ed 112.43%);
  color: white;
}

ul.home_icon_img {
  padding-top: 20px;
  width: 250px;
  padding-left: 10px;
  height: 100px;
}

ul.home_icon_img li a:hover {
  background-color: #23317d;
  color: white;
  border-radius: 28px 0px 0px 25px;
  font-weight: 400;
}

.main_wrapper {
  position: relative;
  width: calc(100% - 250px);
  min-height: calc(100vh - 57px);
  left: 250px;
  top: 57px;
  background-color: #ebefff;
}

.color_div {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 0 0 10px 10px;
}

.main_one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f7ff;
  padding: 15px;
  border-radius: 10px 10px 0 0;
}

.main_two h4 {
  font-weight: 600;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: 0.02em;
  color: #233076;
}

.main_three {
  background: #f5f7ff;
  border-radius: 200px;
  padding: 0px 22px;
}

.delete_icon {
  margin-right: 25px;
  font-size: 20px;
  color: #6a6464;
}

.main_business_details {
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.grid-item {
  border: 1px solid black;
}

.main_three p {
  position: relative;
  top: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #6d79a7;
}

.graph_hold {
  padding: 16px 0px;
}

p.text_Exce {
  font-weight: bold;
  font-size: 10px;
  line-height: 3px;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #3db1db;
}

figure {
  display: block;
  height: 0;
  margin: 0 auto;
  position: relative;
  font-size: 16px;
  font-size: 1vw;
  width: 17em;
  padding-bottom: 17em;
}

figcaption {
  line-height: 2px;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  width: 100%;
  position: absolute;
  left: 0;
  top: 42%;
  z-index: 2;
}

svg {
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: visible;
}

circle {
  fill: rgba(0, 0, 0, 0);
  stroke-width: 31.8309886184;
  stroke-dasharray: 0, 0, 0, 100;
  stroke-dashoffset: 25;
  -webkit-animation: pie1 3s infinite ease both;
  animation: pie1 6s infinite ease both;
}

.pie1 {
  stroke: hsl(180, 70%, 30%);
}

.pie2 {
  stroke: hsl(180, 70%, 50%);
  -webkit-animation-name: pie2;
  animation-name: pie2;
}

.pie3 {
  stroke: hsl(180, 70%, 20%);
  -webkit-animation-name: pie3;
  animation-name: pie3;
}

.pie4 {
  stroke: hsl(180, 0%, 70%);
  -webkit-animation-name: pie4;
  animation-name: pie4;
}

@-webkit-keyframes pie1 {
  50%,
  100% {
    stroke-dasharray: 40, 60, 0, 0;
  }
}

@keyframes pie1 {
  50%,
  100% {
    stroke-dasharray: 40, 60, 0, 0;
  }
}

@-webkit-keyframes pie2 {
  50%,
  100% {
    stroke-dasharray: 0, 40, 30, 30;
  }
}

@keyframes pie2 {
  50%,
  100% {
    stroke-dasharray: 0, 40, 30, 30;
  }
}

@-webkit-keyframes pie3 {
  50%,
  100% {
    stroke-dasharray: 0, 70, 20, 10;
  }
}

@keyframes pie3 {
  50%,
  100% {
    stroke-dasharray: 0, 70, 20, 10;
  }
}

@-webkit-keyframes pie4 {
  50%,
  100% {
    stroke-dasharray: 0, 90, 10, 0;
  }
}

@keyframes pie4 {
  50%,
  100% {
    stroke-dasharray: 0, 90, 10, 0;
  }
}

.inner_wraapper {
  width: 100%;
  padding: 20px 20px 0;
}

p.font_hodl {
  font-weight: 600;
  font-size: 29px;
  line-height: 29px;
  text-align: center;

  color: #233077;
}

.table_one {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.table_two ul li {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: capitalize;

  color: #23317d;
}

.table_number p {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #6d79a7;
  position: relative;
  line-height: 14px;
  top: 8px;
}

p.disable_text {
  border: 1px solid #ced4da;
  padding: 18px 10px;
  border-radius: 5px;
}

.table_number {
  background: #ebefff;
  opacity: 0.5;
  border-radius: 200px;
  padding: 0px 14px;
  margin-bottom: 10px;
}

hr.full_width {
  width: 100%;
  margin-bottom: 13px;
  margin-top: 0px;
  height: 0.78px;
  left: 332px;
  top: 590px;
  background: #c2d4d4;
}

hr.full_margin {
  width: 100%;
  color: #c2d4d4;
  margin-top: 0px;
  margin-bottom: 13px;
  height: 0.78px;
}

ul.text_glop li {
  list-style: none;
}

ul.text_glop li::before {
  content: "\2022";
  color: #0c76d2;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 22px;
}

.row_two {
  margin-top: 33px;
}

.text_busses {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 0px;
}

.your_business h4 {
  padding-top: 8px;
  padding-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #233076;
}

.your_Performance .fas {
  color: #6a6f89;
}

.your_Performance {
  position: relative;
  top: 11px;
}

thead.color_table {
  background: #f5f7ff;
  border-radius: 3px;
}

th.text_c {
  border-bottom: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #6d79a7;
}

td.Risk {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.04em;
  color: #6a6f89;
  padding-top: 16px;
  padding-bottom: 16px;
}

.onw {
  color: #ff0000;
  padding-right: 3px;
  left: 38.61%;
  right: 60.69%;
  top: 55.26%;
  bottom: 44.48%;
}

.o {
  color: #05b49d;
  padding-right: 3px;
  left: 38.61%;
  right: 60.69%;
  top: 57.55%;
  bottom: 42.19%;
}

span.loW_color {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #05b49d;
}

span.loW_colorw {
  color: #ff0000;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
}

span.loW_colorq {
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
}

.tabbedPanels {
  width: 100%;
}

.panelContainer {
  clear: left;
}

.tabs {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* set li properties  */
ul.tabs.fast li {
  display: flex;
  justify-content: left;
  width: 50%;
}

.tabs li {
  float: left;
  width: 240px;
  padding: 0;
  margin: 0;
  text-align: center;
}

/* all formatting goes inside 'a' */
.tabs a {
  display: block;
  text-decoration: none;
  color: #6a6f89;
  font-size: 14px;
  font-weight: 600;
  padding: 12px !important;
  margin-right: 4px;
  /* border: 2px solid green; */
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* background-color: green; */
  margin-bottom: -2px;
  padding-bottom: 20px;
}

.tabs a.active {
  border-bottom: 2px solid #233077;
  background-color: #fff;
  color: #233077;
  font-weight: 600;
}

.table_well {
  margin: 0px 0px;
}

.panel p {
  margin-bottom: 0px;
}

.row_two_hol {
  padding-bottom: 33px;
}

.table {
  text-wrap: nowrap;
}

.sectorQuestion img {
  width: 50%;
  opacity: 0.4;
  margin: 0 auto;
  display: flex;
  height: calc(100vh - 162px);
}

.table.ello > :not(:last-child) > :last-child > * {
  border-bottom-color: snow;
  background-color: #f5f7ff;
  font-size: 13px;
  color: #6d79a7;
  letter-spacing: 0.04em;
  font-weight: 600;
  padding: 14px 14px 12px 23px;
}

tr.holl_text td {
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.04em;

  color: #6a6f89;
}

tr.holl_text td {
  padding: 18px 18px 18px 23px;
}

tr.holl_text td img {
  width: 80px;
}

body {
  width: 100%;
}

ul.tabs.fast li {
  display: flex;
  justify-content: left;
}

.progress-bar-vertical {
  width: 5px;
  min-height: 150px;
  margin-left: 20px;
  background: #d0cece;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  float: left;
  margin-top: 7px;
  margin-left: 0;
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}

.progress-striped {
  background-color: #6d79a7;
  background-image: -webkit-gradient(
    linear,
    0 100%,
    100% 0,
    color-stop(0.25, rgba(255, 255, 255, 0.15)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.two_progress h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #6d79a7;
}

.two_progress p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: #6a6f89;
}

.vertical_row {
  padding: 0 23px;
  height: 300px;
  overflow: auto;
}

.panelContainer .tab-content {
  height: 276px;
  overflow: auto;
}

.view_bottom {
  width: 100%;
  text-align: center;
}

.view_bottom a {
  line-height: 43px;
  background: linear-gradient(94.47deg, #233076 1.14%, #19619c 114.41%);
  justify-content: center;
  text-decoration: none;
  width: 100%;
  left: 879px;
  top: 1176px;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
}

.view_bottom a:hover {
  background: #19619c;
  color: #fff;
}

.hd_process {
  text-align: center;
}

.four_box img {
  height: 163px;
  width: 100%;
}

article.card.animated.fadeInUp {
  background: linear-gradient(105.31deg, #05b49d 8.3%, #00a58f 106%);
  box-shadow: 0px 12px 52px rgb(5 180 157 / 32%);
  border-radius: 8px;
  position: relative;
  height: 330px;
}

.card-block.image {
  position: relative;
  width: 100%;
  height: 46px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 6px;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.text_bill {
  text-align: center;
  padding: 20px 20px;
}

.text_bill {
  text-align: center;
  padding: 20px 20px;
}

p.card-text {
  width: 235px;
  height: 40px;
  left: 1023px;
  top: 1583px;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
}

.card-block {
  justify-content: center;
  height: 121px;
  display: flex;
  align-items: center;
}

article.card.animated.fadeInUp.op {
  background: linear-gradient(273.12deg, #233076 -2.53%, #36469d 98.57%);
  box-shadow: 0px 12px 52px rgb(54 70 157 / 32%);
  border-radius: 8px;
}

article.card.animated.fadeInUp.oplo {
  background: linear-gradient(105.31deg, #41c7ed 8.3%, #1facd5 106%);
  box-shadow: 0px 12px 52px rgb(65 199 237 / 32%);
  border-radius: 8px;
}

.color_div_Programs {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
}

.color_div_point {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding: 30px 24px;
}

.color_div_KATE {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding: 58px 50px 63px 50px;
}

.motor {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
}

a.directly_link {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #4a4e61;
}

a.directly_link {
  justify-content: center;
  display: flex;
  width: 200px;
  padding: 10px 10px;
  text-decoration: none;
  align-items: center;
  width: 200px;
  height: 58px;
  left: 350px;
  top: 378px;
  border: 2px solid #4f5367;
  box-sizing: border-box;
  border-radius: 8px;
}

a.directly_link:hover {
  background-color: #233076;
  color: white;
  border: 2px solid #223077;
}

a.directly_link {
  justify-content: center;
  display: flex;
  width: 200px;
  padding: 10px 10px;
  text-decoration: none;
  align-items: center;
  width: 200px;
  height: 58px;
  left: 350px;
  top: 378px;
  border: 2px solid #4f5367;
  box-sizing: border-box;
  border-radius: 8px;
}

a.directly_link:hover {
  background-color: #233076;
  color: white;
  border: 2px solid #223077;
}

.nop {
  line-height: 14px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #6a6f89;
}

.stepper-svg {
  width: 100%;
}

.stepper-steps {
  display: grid;
}

.row_two_hole {
  margin-top: 33px;
}

.steps-form .steps-row .steps-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.steps-form .steps-row .steps-step p {
  margin-top: 0.5rem;
}

.steps-form .steps-row .steps-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.Allow__card__section {
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 8px;
}

figure {
  display: flex;
}

figure img {
  width: 8rem;
  height: 8rem;
  border-radius: 15%;
  border: 1.5px solid #f05a00;
  margin-right: 1.5rem;
  padding: 1rem;
}

figure figcaption {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

figure figcaption h4 {
  font-size: 1.4rem;
  font-weight: 500;
}

figure figcaption h6 {
  font-size: 1rem;
  font-weight: 300;
}

figure figcaption h2 {
  font-size: 1.6rem;
  font-weight: 500;
}

.order-track {
  margin-top: 2rem;
  padding: 0 1rem;
  border-top: 1px dashed #2c3e50;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
}

.order-track-step {
  display: flex;
  height: 75px;
  width: 26px;
}

.order-track-step:last-child {
  overflow: hidden;
  height: 4rem;
}

.order-track-step:last-child .order-track-status span:last-of-type {
  display: none;
}

.order-track-status {
  margin-right: 1.5rem;
  position: relative;
}

.order-track-status-dot {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 70%;
  background: #6d79a7;
}

.order-track-status-line {
  display: block;
  margin: 0 auto;
  width: 2px;
  height: 70px;
  position: relative;
  width: 67.5px;
  height: 0px;
  left: -31px;
  top: 32px;
  border: 0.5px dashed #353f65;
  transform: rotate(90deg);
}

.order-track-text-stat {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 3px;
}

.order-track-text-sub {
  font-size: 1rem;
  font-weight: 300;
}

.order-track {
  transition: all 0.3s height 0.3s;
  transform-origin: top center;
}

.text_var {
  display: flex;
  justify-content: center;
}

.ONE_PASS {
  position: relative;
  bottom: -7px;
  left: 4px;
}

.four_box img {
  width: 100%;
}

.color_div_ption {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding: 41px 50px;
}

.specialize {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.steps-step a {
  padding: 11px 14px 12px 11px;
  border-radius: 30px;
  width: 242px;
  text-decoration: none;
  color: #4f5367;
}

.strp_progess {
  padding-bottom: 20px;
}

.folop-indigo {
  background-color: white;
}

.color_div_step {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px;
}

.step_icon {
  padding-right: 10px;
}

.step_icon .fa {
  color: #4f5367;
}

.back_quninti {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #4f5367;
  margin-bottom: 0;
}

.E_capital {
  /* padding: 28px 0px 38px 0px; */
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #1f9ed1;
  margin-bottom: 0;
}

.back_text {
  text-decoration: none;
  color: #1f9ed1;
}

.back_text:hover {
  color: #233076;
}

.gement {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #6d79a7;
  background: #ebefff;
  border-radius: 200px;
  display: inline-block;
  padding: 4px 20px;
}

.energy {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  /* padding-top: 11px; */
  margin-bottom: 0;
}

.sumption {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 0;
  /* padding-top: 22px; */
}

hr.related {
  width: 100%;
  height: 1px;
  left: 350px;
  top: 470px;
  background: #05b49d;
  margin-top: 11px;
  margin-bottom: 0;
}

hr.strat {
  width: 100%;
  height: 2px;
  left: 350px;
  top: 593px;
  background: #b8b9bb;
  margin-bottom: 0px;
  margin-top: 44px;
}

.managq {
  padding-top: 55px;
}

.Waste {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #6d79a7;
  width: 140px;
  height: 25px;
  left: 350px;
  top: 655px;
  background: #ebefff;
  border-radius: 200px;
  margin-bottom: 0;
}

.managw {
  padding-top: 60px;
}

hr.manufa {
  margin-bottom: 0px;
  margin-top: 44px;
  width: 100%;
  height: 2px;
  background: #b8b9bb;
}

.sve_next {
  padding-top: 50px;
}

.page_save {
  background: #1f9ed1 !important;
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  border-radius: 8px;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 20px;
}

.page_save:hover {
  background: linear-gradient(
    94.41deg,
    #233076 -47.54%,
    #2eb9e0 114.39%
  ) !important;
  color: #fff;
}

.height-min {
  min-height: 58px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_save_min {
  width: 200px;
  height: 38px;
  left: 350px;
  top: 835px;
  background: linear-gradient(94.47deg, #233076 1.14%, #19619c 114.41%);
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 30px;
  text-decoration: none;
}

.invite_company{
  background: linear-gradient(94.47deg, #233076 1.14%, #19619c 114.41%);
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  color: #ffffff;
  border: none;
  font-size: 12px;
  padding: 5px 30px;
  border-radius: 8px;
}

.page_save_min:hover {
  color: #fff;
}

.next_page {
  width: 200px;
  height: 58px;
  left: 580px;
  top: 835px;
  border: 2px solid #4f5367;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #4a4e61;
  background: white;
}

.page_save:hover {
  background: #1b5895;
  color: #ffffff;
}

.page_save1:hover {
  background: #1b5895;
  color: #ffffff;
}

.page_save1 {
  width: 200px;
  left: 350px;
  top: 835px;
  background: linear-gradient(94.47deg, #233076 1.14%, #19619c 114.41%);
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 30px;
  text-decoration: none;
}

.next_page:hover {
  background: #1c5291;
  color: white;
  border: none;
}

.next_page_one {
  width: 300px;
  height: 58px;
  left: 580px;
  top: 1000px;
  border: 2px solid #05b49d;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #05b49d;
  background-color: white;
}

span.Download_r {
  padding-right: 9px;
}

.gementz {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #6d79a7;
  height: 25px;
  background: #ebefff;
  border-radius: 200px;
  width: 173px;
}

span.order-track-status-dot_one:before {
  content: "";
  display: block;
  width: 65px;
  height: 1px;
  background: #d3d9ef;
  left: 310px;
  top: 50%;
  position: absolute;
}

span.order-track-status-dot_two {
  border-radius: 57%;
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background: #d3d9ef;
  left: 373px;
  top: 19px;
  position: absolute;
}

span.order-track-status-dot_three:before {
  content: "";
  display: block;
  width: 65px;
  height: 1px;
  background: #d3d9ef;
  left: 378px;
  top: 50%;
  position: absolute;
}

button.next_page_one:hover {
  background: linear-gradient(94.47deg, #233076 1.14%, #19619c 114.41%);
  color: white;
  border: none;
  border: 2px solid #1c5593;
}

.page_save a {
  color: white;
  text-decoration: none;
}

.sector_link {
  width: 200px;
  height: 58px;
  left: 350px;
  top: 835px;
  background: linear-gradient(94.47deg, #233076 1.14%, #19619c 114.41%);
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 30px;
}

/*sector question_fast*/

/*sector question_two*/
.color_div_ption {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding: 41px 50px;
  margin-bottom: 20px;
}

.f_capital {
  padding: 0px 0px 35px 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
}

p.Edit.Answer {
  float: right;
  margin-bottom: 0;
}

.link_pen {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #6d79a7;
  text-decoration: none;
}

span.Answer {
  bottom: 41.83%;
  color: #6d79a7;
  font-size: 12px;
  margin-right: 4px;
}

/*two*/

/*sector question three*/
.mix_code {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 0;
}

.mix_p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  padding-top: 17px;
}

.managd {
  margin-bottom: 50px;
}

.color_one_ption {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding: 37px 34px;
  margin-bottom: 20px;
  margin-left: -40px;
  margin-right: -40px;
  margin-top: 28px;
}

.consumption {
  width: 120px;
  height: 36px;
  left: 1230px;
  top: 949px;
  background: linear-gradient(94.47deg, #233076 1.14%, #19619c 114.41%);
  box-shadow: 0px 8px 52px rgb(64 84 164 / 21%);
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #ffffff;
}

.colp {
  display: flex;
  justify-content: flex-end;
}

.managu {
  padding-top: 40px;
}

/*sector question three*/
/*9/24/21*/

/*9/27/21*/
.color_div_on {
  background: #ffffff;
  /* border-radius: 0px 0px 8px 8px; */
  padding: 10px 10px 0;
}

.directly {
  padding-top: 46px;
}

.table>thead {
  position: sticky;
  top: -1px;
  z-index: 9;
}

.add____broker{
  text-align: end;
}

.add____broker button{
  padding: 10px 30px;
}

.table___height {
  height: calc(100vh - 141px);
  overflow: auto;
}
/*ESg Reporting*/

.Introduction {
  background: #ffffff;
  border-radius: 8px;
  padding-top: 41px;
  padding-bottom: 41px;
  padding-left: 50px;
  /* height: 212px; */
  padding-right: 50px;
  box-shadow: 0px 0px 15px rgba(25, 97, 156, 0.2);
}

.Card__section {
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
  background: #fff;
}

.Intro {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #1f9ed1;
}

.critical {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.frameworks {
  height: 532px;
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  margin-top: 20px;
  padding-top: 45px;
  padding-left: 40px;
}

.frame {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  padding-bottom: 34px;
  margin-bottom: 0;
}

.input_one {
  border: 1.4px solid #d6deec !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  width: 18px !important;
  height: 18px !important;
  margin-top: 0 !important;
}

.label_one {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #4f5367;
  padding-left: 12px;
}

.input_one:checked {
  background: #05b49d;
  box-shadow: 0px 4px 12px rgb(5 180 157 / 30%);
  border-radius: 2px;
  border-color: #05b49d;
}

.clobal_check {
  margin-bottom: 0;
  padding-bottom: 37px;
}

a.link_bal {
  width: 200px;
  height: 58px;
  background: linear-gradient(94.47deg, #233076 1.14%, #19619c 114.41%);
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
}

.save_global {
  display: flex;
}

.link_bal_next {
  border: 1px solid #1f9ed1;
  border-radius: 4px;
  line-height: 11px;
  padding: 10px;
  text-transform: uppercase;
  color: #1f9ed1;
  background: transparent;
  transition: 1s;
}

.link_bal_next_cancel {
  border: 1px solid red;
  border-radius: 4px;
  line-height: 11px;
  padding: 10px;
  text-transform: uppercase;
  color: #fff;
  background: red;
  transition: 1s;
}

.global_link {
  margin-left: 30px;
}

.link_bal:hover {
  background: #19619c;
}

.link_bal_next:hover {
  background: #1f9ed1 !important;
  color: white;
  border: 1px solid #1f9ed1 !important;
  transition: all 0.2s ease-in-out;
}

.link_bal_next:hover .white {
  color: #fff;
}

.Below_demo {
  background: #ffffff;
  border-radius: 8px;
  padding: 41px 50px;
  box-shadow: 0px 0px 15px rgba(25, 97, 156, 0.2);
}

.back_text_icon {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #4f5367;
  text-decoration: none;
  justify-content: left;
}

.step_icon_text .far {
  color: #4f5367;
  padding-right: 10px;
  position: relative;
  bottom: 1px;
}

.Below {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 34px;
}

.back_text_icon:hover {
  color: #4f5367;
}

.back_quninti_below {
  margin-bottom: 25px;
}

.ronment {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #23317c;
  margin-bottom: 27px;
}

.Ethics {
  width: 100%;
  height: 2px !important;
  left: 350px;
  top: 795px;
  background: #ebefff;
  margin-top: 50px;
  margin-bottom: 48px;
}

.Management img {
  width: 100%;
}

.Management {
  text-align: center;
}

.Management img {
  width: 100%;
}

/*SG_Rporting*/

/*govemence*/
.governance {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding-top: 41px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 94px;
}

.governance1 {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding-top: 0px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 94px;
}

.governance_head {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
  margin-bottom: 0;
}

.governance_p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 0;
}

.reviewed_h {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.reviewed {
  padding: 37px 0px 18px 0px;
}

span.policy_icon .fa {
  color: #4f5367;
}

.policy {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

span.policy_icon .fal {
  font-size: 9px;
  margin-right: 6px;
  color: #4f5367;
  position: relative;
  bottom: 2px;
}

p.succeed_one {
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.without_icon {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  opacity: 0.2;
  position: absolute;
  top: 40px;
  left: 37px;
}

.Mortiz {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 46px;
}

.succeed {
  position: relative;
  height: 245px;
  background: #ffffff;
  border: 2px solid #ebefff;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 60px;
  padding: 37px 38px 0px 57px;
}

.Statement_one {
  justify-content: space-between;
  display: flex;
  padding: 14px 21px;
}

.statement_p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 0;
}

.statement_pen .fas {
  color: #a7acc8;
  padding-right: 21px;
}

.statement_check .far {
  color: #05b49d;
}

.statement_Upload {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #233076;
  opacity: 0.4;
}

.statement_fasd .fas {
  color: #a7acc8;
  padding-right: 8px;
}

.Statement_onew {
  justify-content: space-between;
  display: flex;
  padding: 14px 21px;
  border: 1px solid #edf1fc;
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
}

.statement_icon {
  margin-bottom: 0;
}

.Statement_onec {
  justify-content: space-between;
  display: flex;
  padding: 14px 21px;
  border: 1px solid #edf1fc;
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
}

.Statement_onec {
  justify-content: space-between;
  display: flex;
  padding: 14px 21px;
  align-items: center;
  background: rgba(5, 180, 157, 0.1);
  border: 1px solid rgba(5, 180, 157, 0.8);
  box-sizing: border-box;
  border-radius: 4px;
}

.statement_sus {
  margin-bottom: 0;
}

.statement_circle .fas {
  color: #05b49d;
  padding-right: 6px;
}

.statement_checkr {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #05b49d;
}

.statement_re .fas {
  color: #a7acc8;
  padding-right: 7px;
}

.statement_re_Upload {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #233076;
  opacity: 0.4;
  padding-right: 24px;
}

.statement_delit .far {
  color: #a7acc8;
}

.statement_icon_re {
  margin-bottom: 0;
}

.statement_icono {
  margin-bottom: 0;
}

.Statement_one {
  margin-bottom: 0px;
}

.Statement_onew {
  margin-bottom: 24px;
}

.Statement_onec {
  margin-bottom: 24px;
}

.Statement_2 {
  justify-content: center;
  align-items: center;
}

textarea.box_layout {
  width: 300px;
  margin-top: 15px;
  height: 75px;
}

.sve_next_one {
  margin-top: 64px;
}

.suppliers_p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 15px;
}

.suppliers_t {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 35px;
}

.Identified_text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.chains {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.include_h {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 0;
  padding-bottom: 19px;
}

.suppli_two {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 54px;
}

.critical_h {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
}

.supply_chain {
  margin-top: 28px;
  margin-bottom: 35px;
}

.sup_can {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 58px;
}

.four_text {
  background: red;
  width: 100%;
  height: 270px;
  left: 350px;
  top: 288px;
  background: #05b49d;
  border-radius: 12px 0px 0px 0px;
  padding: 66px 38px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.as_usual {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.operating_m {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #ffffff !important;
  margin-bottom: 16px;
}

.four_text_one {
  width: 100%;
  height: 270px;
  left: 850px;
  top: 288px;
  background: #233076;
  border-radius: 0px 12px 0px 0px;
  padding: 66px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.four_text_two {
  width: 100%;
  height: 270px;
  left: 350px;
  top: 558px;
  background: #41c7ed;
  border-radius: 0px 0px 0px 12px;
  padding: 40px 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.four_text_there {
  width: 100%;
  height: 270px;
  background: #19619c;
  border-radius: 0px 0px 12px 0px;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.high_risk {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #ffffff !important;
}

.high_icon {
  padding-right: 8px;
  color: #ffffff;
}

.save_Governance {
  display: flex;
}

.home_risck {
  padding-bottom: 60px;
}

.text_w {
  height: 70px;
  background: rgba(245, 247, 255, 0.4);
  border: 1px solid #ebefff;
  box-sizing: border-box;
  border-radius: 2px;
}

.nature_one {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 4px;
}

.text_w {
  margin-bottom: 21px;
}

.bop {
  margin-bottom: 21px;
}

.select_one {
  width: 100%;
  background: rgba(245, 247, 255, 0.4);
  border: 1px solid #ebefff;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: none;
  color: #777777;
}

textarea.form-control.text_w {
  box-shadow: none;
  color: #000;
}

.Environmental_text {
  padding-bottom: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
  margin-bottom: 0;
}

hr.imact_yum {
  height: 2px;
  background: #ebefff;
  margin: 60px 0;
}

.suppliers_m {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 15px;
}

.Supplier_Manage {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
  margin-bottom: 17px;
}

.ssess_h {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 20px;
}

.Assessment {
  padding-top: 40px;
}

.Assessment_two {
  padding-top: 41px;
}

.save_Assessment {
  padding-top: 48px;
}

.accountable {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 15px;
  padding-right: 30px;
  text-align: justify;
}

.requirem {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding-top: 50px;
  padding-left: 51px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.back_requirem {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #4f5367;
  margin-bottom: 24px;
}

.crit {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  padding-top: 48px;
  padding-bottom: 28px;
}

.suppliers_text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  padding-bottom: 34px;
}

.sup_any {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #4f5367;
  padding-bottom: 4px;
}

.supplier_t {
  padding-top: 22px;
  padding-bottom: 12px;
}

.h_g {
  box-shadow: none;
  height: 44px;
  background: rgba(245, 247, 255, 0.4);
  border: 1px solid #ebefff;
  box-sizing: border-box;
  border-radius: 2px;
}

.h_g:hover {
  box-shadow: none;
}

.supploi {
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #05b49d;
}

.supploi:hover {
  text-decoration: none;
  color: #05b49d;
}

p.link_ctp a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #4f5367;
}

.faot {
  color: #4f5367;
  font-size: 30px;
  padding-bottom: 9px;
}

.upload_txt {
  padding: 32px 19px 31px 18px;
  border: 1px dashed #dee2f2;
  box-sizing: border-box;
  border-radius: 6px;
  background: none;
}

.cct {
  padding: 34px 0px 40px 0px;
}

.issue_it {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #23317c;
}

.issue_it {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
  color: #23317c;
  width: 400px;
  height: 40px;
  left: 350px;
  top: 702px;
  border: 1px solid #23317c;
  box-sizing: border-box;
  border-radius: 4px;
}

hr.heling {
  margin: 60px 0;
  height: 1px;
  left: 350px;
  top: 802px;
  background: #ebefff;
  border-radius: 0px;
}

.back_Introdu {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
}

.interlinked {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 10px;
}

.towards {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 37px;
}

.addres_p {
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.addres {
  padding: 36px 38px 56px 58px;
  background: #ffffff;
  border: 2px solid #ebefff;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
}

span.finan .fas {
  position: absolute;
  left: 32px;
  top: 40px;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: #4f5367;
  opacity: 0.2;
}

.Brian {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.image_icon {
  width: 100px;
  height: 100px;
  background: #468aca;
  border-radius: 8px;
  padding: 16px 9px 14px 9px;
}

.demons .far {
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-size: 45px;
  position: relative;
  text-align: center;
  align-items: center;
  margin: 0 auto;
}

.demons_te {
  font-style: normal;
  font-weight: bold;
  font-size: 8px !important;
  line-height: 9px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #ffffff !important;
}

.demons {
  margin-bottom: 6px;
}

.demons1 {
  margin-bottom: -32px;
}

.chairamw {
  display: flex;
  justify-content: left;
  align-items: center;
}

.Developme {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.impa {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.can_bus {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 0;
}

.chairam {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.omne {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 0;
}

.of_America {
  width: 100%;
  height: 140px;
  left: 351px;
  top: 940px;
  background: #fbfcff;
  border-radius: 4px 4px 0px 0px;
  padding: 15px 10px 15px 10px;
  border: 1px solid #ebebeb;
}

.addres {
  padding: 36px 38px 56px 58px;
  background: #ffffff;
  border: 2px solid #ebefff;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  margin-bottom: 51px;
}

.image_text {
  padding-left: 20px;
}

.Pove_rty {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 7px;
}

.image_icon_one {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #f4364c;
  border-radius: 8px;
}

.demons .fas {
  left: 394px;
  top: 966px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-size: 45px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: rgba(120, 120, 128, 0.16);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #34c759;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.of_Americat {
  width: 100%;
  left: 351px;
  top: 940px;
  background: #fbfcff;
  border-radius: 4px 4px 0px 0px;
  padding: 5px 10px;
}

.home_help {
  border: 1px solid #ebefff;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 60px;
}

hr.human {
  height: 1px;
  left: 350px;
  top: 1200px;
  background: #b7bac7;
  border-radius: 0px;
  margin-top: 12px;
  margin-bottom: 0;
}

.nopel {
  width: 200px;
  height: 80px;
  background: rgba(245, 247, 255, 0.4);
  border: 1px solid #ebefff;
  box-sizing: border-box;
  border-radius: 2px;
}

.image_icon_oneq {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #f1b434;
  border-radius: 8px;
}

.image_icon_onew {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #4b9560;
  border-radius: 8px;
}

.image_icon_onee {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #cb2c30;
  border-radius: 8px;
}

.image_icon_onet {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #cb2c30;
  border-radius: 8px;
}

.image_icon_onep {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #e04e39;
  border-radius: 8px;
}

.demons .fab {
  left: 394px;
  top: 966px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-size: 45px;
}

.image_icon_oney {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #41b6e6;
  border-radius: 8px;
}

.image_icon_oneu {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #f0b323;
  border-radius: 8px;
}

.image_icon_onea {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #b52555;
  border-radius: 8px;
}

.image_icon_onei {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #e87722;
  border-radius: 8px;
}

.image_icon_onei {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #e31c79;
  border-radius: 8px;
}

.image_icon_onen {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #e87722;
  border-radius: 8px;
}

.image_icon_oneb {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #f1b434;
  border-radius: 8px;
}

.image_icon_oneop {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #c67d30;
  border-radius: 8px;
}

.image_icon_oneop {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #c67d30;
  border-radius: 8px;
}

.image_icon_onemo {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #228848;
  border-radius: 8px;
}

.image_icon_oneon {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #298fc2;
  border-radius: 8px;
}

.image_icon_onenm {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #6cc24a;
  border-radius: 8px;
}

.image_icon_onecm {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #006ba6;
  border-radius: 8px;
}

.image_icon_onegl {
  width: 100px;
  height: 100px;
  padding: 16px 9px 14px 9px;
  background: #005776;
  border-radius: 8px;
}

.demons .fa {
  left: 394px;
  top: 966px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-size: 45px;
}

.color_div_carbon {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding: 41px 50px;
}

.Footprint {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
  margin-bottom: 16px;
}

.footprint_text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 6px;
}

.footprint_text_one {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 22px;
}

.I_need {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 12px;
}

.need_text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 15px;
}

.facilities {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 16px;
}

.facilities_icon {
  color: #4f5367;
  font-size: 12px;
  margin-right: 8px;
}

ul.whomevr li {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 10px;
}

.calculate {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 12px;
}

.data_is {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 31px;
}

.stent_one {
  display: flex;
}

.stent_table {
  width: 30%;
}

.stent_table_one {
  width: 30%;
}

.stent_table_two {
  width: 40%;
}

.scope {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.stent {
  background: #fbfcff;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #efefef;
  padding: 14px 31px;
}

hr.scope_gelop {
  color: #c9c8c8;
}

.ghg {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.kp {
  margin-right: 30px;
}

p.ruction {
  width: 237px;
  height: 44px;
  left: 242px;
  top: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  position: relative;
}

.color_div_carbonw {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding: 41px 50px;
}

.Capital_op {
  margin: 28px 0px 37px 0px;
}

.text_blod {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.text_het {
  width: 450px;
  height: 48px;
  background: rgba(245, 247, 255, 0.4);
  border: 1px solid #ebefff;
  box-sizing: border-box;
  border-radius: 2px;
}

.E_Emis {
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
  margin-bottom: 0;
}

.vbn {
  margin-bottom: 22px;
}

.suppliers_gover {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 29;
}

.exis_skills {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 0;
}

.ident {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
}

p.experience_and {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.Assessment_opm {
  padding-top: 43px;
}

.Member_chain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
}

.menber_j {
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #05b49d;
  text-decoration: none;
  margin-bottom: 0;
}

.text_glop {
  display: flex;
  justify-content: left;
  align-items: center;
}

.Willi {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 4px;
}

p.graphic_pri {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #4f5367;
  word-break: break-all;
}

.velle {
  background: #ffffff;
  border: 1px solid #ebefff;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 25px 26px;
}

.helop_text {
  position: relative;
  top: 10px;
}

h4.matterexperties {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #4f5367;
  margin-bottom: 7px;
}

.text_image_ne {
  width: 120px;
  text-align: center;
  position: relative;
  bottom: 70px;
}

ul.grapic li {
  list-style: none;
}

ul.grapic {
  display: flex;
  margin-bottom: 0;
}

.t_matter {
  margin-bottom: 26px;
}

ul.grapic li a {
  background: #f5f7ff;
  border-radius: 100px;
  padding: 6px 16px;
  margin-left: 6px;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #6d79a7;
}

.velle {
  margin-bottom: 20px;
}

.text_image_ne img {
  border-radius: 66px;
  width: 90px;
  height: 90px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.member {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #05b49d;
  text-decoration: none;
}

.global_link {
  margin-left: 30px;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.member:hover {
  color: #05b49d;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.hoell {
  top: 95px;
}

.name_help {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #4f5367;
  padding-bottom: 11px;
}

.mellp {
  /* padding: 20px; */
  background: rgba(245, 247, 255, 0.4);
  border: 1px solid #ebefff;
  box-sizing: border-box;
  border-radius: 2px;
  height: 44px;
  width: 400px;
}

.convell {
  padding-bottom: 24px;
}

.text_up {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #4f5367;
  margin-bottom: 0;
}

.text_np {
  background: rgba(245, 247, 255, 0.4);
  border: 1px solid #ebefff;
  box-sizing: border-box;
  border-radius: 2px;
  width: 400px;
  height: 70px;
}

.fline_img {
  padding: 41px 66px;
  text-align: center;
  border: 1px dashed #dee2f2;
  box-sizing: border-box;
  border-radius: 6px;
}

.up_img {
  margin-bottom: 0;
}

.up_img .far {
  font-size: 37px;
  color: #4f5367;
}

.metter_text {
  padding-top: 37px;
  padding-bottom: 25px;
}

.input_onehl {
  width: 16px;
  height: 16px;
}

.label_onekl {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.input_onehl {
  position: relative;
  bottom: 5px;
}

.input_onehl:checked {
  background: #05b49d;
  border: 1px solid #05b49d;
}

.clobal_checkup {
  padding-bottom: 28px;
}

.metter_one {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.vante {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #4f5367;
  padding-bottom: 25px;
}

.products_ava {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 30px;
}

.esg_reali {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 22px;
  margin-top: 0;
}

.suppl {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.Introductionwe {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding-top: 41px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 135px;
}

.frameje {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 28px;
}

.our_esg {
  margin-bottom: 13px;
}

.rellp {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 5px;
}

.bellpo {
  width: 680px;
  height: 100px;
  background: rgba(245, 247, 255, 0.4);
  border: 1px solid #ebefff;
  box-sizing: border-box;
  border-radius: 2px;
}

.lease_uop {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 38px;
}

.form_fe {
  margin-bottom: 51px;
}

.link_bal_hop {
  width: 320px;
  height: 58px;
  background: linear-gradient(94.47deg, #233076 1.14%, #19619c 114.41%);
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
}

.link_bal_hop:hover {
  background: #19619c;
  color: white;
}

.regularly_ava {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 28px;
}

.Introductionweq {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding-top: 41px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 63px;
}

.redlop {
  padding-top: 30px;
}

.ticels {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  margin-bottom: 17px;
}

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.row_two_tte {
  position: relative;
}

.iamge_car img {
  width: 100%;
}

.serhelp {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #233076;
  margin-top: 14px;
  margin-bottom: 0px;
  text-decoration: none;
}

p.fx_fin {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 60px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #6a6f89;
  margin-bottom: 0;
}

img.im_k {
  border-radius: 9px;
}

.save_globalw {
  padding-top: 60px;
}

.Introductionweqw {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding-top: 41px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 52px;
}

.Academy {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
  padding-top: 28px;
  padding-bottom: 26px;
}

.image_video img {
  width: 812px;
  height: 410px;
  left: 350px;
  top: 279px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0) 0%,
    #000000 100%
  );
  border-radius: 24px;
}

.image_video {
  margin-bottom: 60px;
}

.global_linkE {
  margin-left: 0px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.Coach {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
  margin-top: 28px;
  margin-bottom: 26px;
}

.supp_bel {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #4f5367;
  margin-bottom: 28px;
}

.homele {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4f5367;
}

.selpe {
  width: 450px;
  height: 48px;
  background: #f8fbff;
  border: 1px solid #f1f5fb;
  box-sizing: border-box;
  border-radius: 4px;
}

.form_DESH {
  padding-bottom: 37px;
}

.expltion {
  width: 450px;
  height: 100px;
  left: 350px;
  top: 481px;
  background: #f8fbff;
  border: 1px solid #f1f5fb;
  box-sizing: border-box;
  border-radius: 4px;
}

.expltion {
  text-align: right;
  padding-top: 75px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #4f5367;
  opacity: 0.6;
}

.global_linkwe {
  display: flex;
  justify-content: left;
  align-items: center;
}

.save_globaler {
  display: flex;
  padding-top: 61px;
  padding-bottom: 263px;
}

.oneby {
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
}

.oneby input.form-control {
  width: 350px;
  height: 42px;
  background: #ebefff;
  opacity: 0.5;
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: #c5cbe2;
}

h4.ssional {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #233076;
}

.text_ntroduction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 28px;
}

.pment img {
  width: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0) 0%,
    #000000 100%
  );
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08));
  border-radius: 8px;
  height: 230px;
}

p.graphic_nd {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #4f5367;
  padding-top: 11px;
}

.two_roll {
  padding-top: 44px;
}

.actual {
  font-style: normal;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #6a6f89;
  padding: 8px 24px;
  text-align: justify;
}

h5.not_show {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: #6a6f89;
}

.ancy {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #6a6f89;
}

.pol {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #6a6f89;
}

ul.sp_po li {
  list-style: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #6a6f89;
  padding-bottom: 15px;
}

h4.progam_commend {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #233076;
  margin-bottom: 0;
}

p.num_text {
  width: 50px;
  height: 25px;
  left: 735px;
  top: 599px;
  background: #ebefff;
  border-radius: 200px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #6d79a7;
  display: flex;
  justify-content: center;
}

.over_view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding: 11px 9px;
  background: #f5f7ff;
  border-radius: 2px;
}

.model_text {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #23317d;
  margin-bottom: 0;
}

.busins_point {
  display: flex;
  justify-content: space-between;
}

.above_text {
  padding-right: 58px;
}

p.nam_hol {
  margin-bottom: 0;
  width: 50px;
  height: 25px;
  left: 735px;
  top: 599px;
  background: #ebefff;
  border-radius: 200px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #6d79a7;
  justify-content: center;
  display: flex;
}

p.aver_icon {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #6a6f89;
}

.tio {
  color: #05b49d;
  font-size: 13px;
}

p.prbus {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #23317d;
  padding-left: 10px;
}

.busins_point {
  padding-top: 23px;
  padding-bottom: 8px;
}

.f_Impr {
  padding-left: 10px;
}

p.Area_of {
  font-weight: 600;
  color: #3cafda;
}

.new_button_style {
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  background: linear-gradient(90deg, #1f9ed1 0%, rgb(0 149 209 / 40%) 100%);
}

.new_button_style-red {
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  background: linear-gradient(90deg, red 0%, red 100%);
}

.new_button_style-green {
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  background: linear-gradient(90deg, green 0%, green 100%);
}

.tabele_div {
  height: 689px;
  overflow: auto;
}

p.Corpor {
  margin-bottom: 0;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  color: #6d79a7;
}

hr.ofice {
  color: #d2d6e7;
  margin: 20px 0px;
}

.busins_pointw {
  display: flex;
  justify-content: space-between;
}

.bussins {
  width: 230px;
}

.above_text {
  width: 244px;
}

.tio {
  padding-right: 4px;
}

.jop {
  font-size: 13px;
  padding-right: 4px;
  color: #ff0000;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

p.aver_icon .fas {
  color: red;
  font-size: 13px;
  padding-right: 4px;
}

.Download_lo {
  width: 100%;
  height: 43px;
  left: 325px;
  top: 1073px;
  background: #ebefff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

a.Download_ur {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #6a6f89;
  text-decoration: none;
}

span.home_boom {
  /* margin-left: 15px; */
  margin-left: 8px;
}

/*.active {
    background: linear-gradient( 
91.53deg, #233076 13.86%, #41C7ED 112.43%);
    color: white;
    border-radius: 28px 0px 0px 25px !important;
    font-weight: 400;
}*/
a.btn-circle.folop-default.folop-indigo {
  color: #05b49d;
  border: 1px solid;
}

span.order-track-status-dot_onee:before {
  content: "";
  display: block;
  width: 25px;
  height: 1px;
  background: #d3d9ef;
  left: 275px;
  top: 50%;
  position: absolute;
}

span.order-track-status-dot_twoo {
  border-radius: 57%;
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background: #d3d9ef;
  left: 298px;
  top: 19px;
  position: absolute;
}

span.order-track-status-dot_threee:before {
  content: "";
  display: block;
  width: 25px;
  height: 1px;
  background: #d3d9ef;
  left: 300px;
  top: 50%;
  position: absolute;
}

/*.active{
  background-color:#d90000;
  color:#fff;
   


/*10/8/2021*/
/*11/10/2021*/

.color_div_Current {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding: 35px 28px;
}

.camad {
  margin-top: 25px;
}

.home_text {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #233076;
  margin-bottom: 0;
}

.home_text {
  margin-bottom: 17px;
}

.curren_text img {
  width: 100%;
  height: 183px;
  border-radius: 10px;
  object-fit: cover;
}

.dete_time {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #6a6f89;
  margin-bottom: 2px;
}

.Austr_text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #233076;
  padding-bottom: 29px;
}

.Pacific {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4f5367;
}

.flop_two {
  padding-top: 38px;
}

.comleted_dell {
  padding-top: 16px;
  margin-bottom: 0;
  text-align: center;
}

.comleted_dell a {
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: #233076;
  transition: 1s;
}

.comleted_dell a:hover {
  background: #c4c4c4;
  padding: 10px 30px;
  border-radius: 20px;
  color: #fff !important;
}

.home-align a {
  width: 100%;
  justify-content: center;
}

.slill_bord {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.slill_bord img {
  width: 100%;
}

span.home_boom {
  position: inherit;
  z-index: 9999;
}

hr.plaform_hr {
  position: inherit;
  bottom: 90px;
  z-index: 999999;
  height: 1px;
  background: #ffffff;
  opacity: 0.1;
  border-radius: 0px;
}

.golp {
  border-left: 1px solid #ebefff;
  height: 500px;
  position: absolute;
  right: 0;
  width: 1px;
}

.better_dic {
  position: relative;
}

/*11/10/2021*/

/*13/10/2021*/
.global_hell {
  display: flex;
  justify-content: left;
  align-items: center;
}

.high_icon .fal {
  font-size: 9px;
  position: relative;
  bottom: 2px;
}

.save_globall {
  display: flex;
  margin-top: 60px;
}

.save_globall {
  display: flex;
  margin-top: 33px;
}

section.login {
  width: 100%;
}

.login_part {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sing_log {
  width: 40%;
  background: #ffffff;
  border-radius: 16px;
  padding: 20px 60px;
}

.Login_panel{
  width: 550px;
  background: #ffffff;
  border-radius: 16px;
  padding: 40px;
}

.sing_one img {
  width: 65%;
}

.sing_one {
  text-align: center;
}

.text_sing {
  text-align: center;
}

.Account {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #2b377b;
  margin-bottom: 6px;
}

.faster_oval {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #6b6b6b;
  margin-bottom: 54px;
}

.name_nf {
  background: #f8fbff;
  border: 1px solid #f1f5fb;
  box-sizing: border-box;
  border-radius: 6px;
  height: 52px;
}

.name_nf {
  background: #f8fbff;
  border: 1px solid #f1f5fb;
  box-sizing: border-box;
  border-radius: 6px;
}

.make_form {
  margin-bottom: 24px;
}

/* .select_map {
  height: 52px;
  left: 421px;
  top: 559px;
  background: #f8fbff;
  border: 1px solid #f1f5fb;
  box-sizing: border-box;
  border-radius: 6px;
  color: #333333;
} */

.form_link {
  width: 100%;
  text-align: center;
}

.view_bottoma a {
  /* justify-content: center; */
  /* width: 100%; */
  border-radius: 8px;
  /* font-style: normal; */
  font-weight: bold;
  /* font-size: 21px; */
  /* display: flex; */
  /* align-items: center; */
  /* text-align: center; */
  /* letter-spacing: 0.1em; */
  text-transform: uppercase;
  color: #ffffff;
  background: #1f9ed1;
  /* height: 60px; */
  /* line-height: 26px; */
  /* max-width: 260px; */
  padding: 10px 20px;
}

.view_bottoma a:hover {
  background: #19619c;
}

.hompop {
  display: flex;
  justify-content: left;
  align-items: center;
}

.deckle {
  width: 22px;
  height: 22px;
  border: 2px solid #dddddd;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 3px;
}

.deckle:checked {
  background-color: #05b49d;
  border-color: #05b49d;
}

.date_yup {
  padding-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
}

.plform {
  margin-bottom: 20px;
}

.glee {
  margin-top: 20px;
}

/* Pagination */

.pagination_billing2 li.next {
  position: unset;
  padding: 0;
  margin: 0;
}

.pagination_billing2 ul.pagination {
  display: flex;
  justify-content: center;
}

.pagination_billing2 .pagination li {
  margin: 0 10px;
  border: 1px solid #b9b9b9;
  width: 70px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination_billing2 .pagination li.active {
  background: #233076;
  color: #fff;
  border: none;
}

.global_link .page_save.page_width {
  color: #fff;
  text-decoration: none;
}

.global_link .page_save.page_width:hover {
  color: #fff;
}

.white:hover {
  color: #fff !important;
}

.pb3 h4 {
  font-weight: 700;
  margin: 0;
}

.pb3 .dropdown {
  display: flex;
  flex-direction: column;
}

.pb3 .dropdown input#dropdownMenuLink {
  height: 52px;
  background: #f1f5fb;
}

.pb3 .dropdown-menu.border-0.shadow.show {
  width: 100%;
}

.pb3 .dropdown .shadow ul {
  height: 213px;
}

.pb4 .input-group input.form-control {
  padding-left: 45px;
  height: 52px;
  margin-bottom: 20px;
}

.pb4 .input-group {
  position: relative;
}

.pb4 .input-group .form-control-feedback.search-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  color: #abb1c8;
}

.pb4 .input-group .dropdown-menu.show {
  width: 100%;
  margin-top: 15px;
}

.pb4 .dropdown-item.form.d-flex.justify-content-between {
  margin: 0;
  border-bottom: 1px solid #ebefff;
  padding: 20px 20px;
}

.pb4 .form-check-inline label.form-check-label {
  font-size: 24px;
}

.pb4 .form-check-inline input#inlineRadio1 {
  margin-top: 8px;
}

.supp4 label {
  font-weight: 700;
  color: #333333;
}

.form-head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}

.form-head .form-group {
  width: 100%;
  display: flex;
}

.form-head .form-group .form-control {
  height: 51px;
  margin: 0 20px;
}

.form-head .form-group span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_text a {
  font-size: 14px;
}

.question_form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.color-blue {
  color: #2b377b;
}

@media only screen and (max-width: 1600px) {
  .div1 .Data {
    left: -450px;
  }

  .div1 .left-t {
    left: -200px;
  }
}

/* Environmental Capital*/

.tabs-tops {
  width: 100%;
  padding: 40px 25px 0;
}

.tabs-top ul {
  display: flex;
  padding: 0;
  margin: 0;
}

.tabs-top ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.tabs-top ul li a.activee {
  border-bottom: 2px solid #fff;
  color: #1f9ed1;
  background: #fff;
  font-weight: 700;
  border-top: 2px solid;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.tabs-top ul li a.activee::before{
  position: absolute;
  content: " ";
  left: -30px;
  bottom: 0px;
  width: 30px;
  height: 30px;
  background: transparent;
  box-shadow: 6px 8px 0px 0 #fff;
  border-radius: 0px 0px 18px 0px;
}

.tabs-top ul li a.activee::after{
  position: absolute;
  content: " ";
  right: -34px;
  bottom: 0;
  width: 30px;
  height: 30px;
  background: transparent;
  box-shadow: -14px 9px 0px 0 #fff;
  border-radius: 0px 0px 0px 18px;
}

.tabs-top ul li a {
  padding: 10px 50px;
  color: #fff;
  white-space: nowrap;
}

.tabs-top ul li::after{
  content: "|";
  color: #fff;
  margin: 0 -4px;
}

.invited_company_icos i {
  font-size: 14px;
  cursor: pointer;
}

.mr-3 {
  margin-right: 30px !important;
}

.list-group-item-light i {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

span.leaf-fer {
  display: flex;
}

a.list-group-item.list-group-item-action.list-group-item-light.p-3.nop:hover {
  background: linear-gradient(
    90deg,
    #1f9ed1 0%,
    rgb(0 149 209 / 45%) 100%
  ) !important;
}

.statement_two .d-flex .toast-header {
  border-bottom: none !important;
  border: none !important;
}

.border-none {
  border: none !important;
}

button.page_width.page_save.close {
  padding: 4px 9px !important;
  text-align: center !important;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50%;
}

button.page_width.page_save1.close {
  padding: 0 12px !important;
  text-align: center !important;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  margin: 3px 35px 0 0px;
}

.caret-down {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 26px;
}

.bc-text {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

a.list-group-item.list-group-item-action.list-group-item-light {
  background: none !important;
}

a.list-group-item.list-group-item-action.list-group-item-light.p-3.nop:hover {
  background: linear-gradient(
    90deg,
    #1f9ed1 0%,
    rgb(0 149 209 / 45%) 100%
  ) !important;
  font-weight: 600;
  font-size: 13px;
}

.uploer_fline {
  height: 100%;
}

.fline_img {
  padding: 20px;
  text-align: center;
  border: 1px dashed #dee2f2;
  box-sizing: border-box;
  border-radius: 6px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Pagination */
.example .pagination {
  display: flex;
  justify-content: center;
}

.example .pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background: #2d619d;
  border-color: #2d619d;
  outline: none;
  color: #fff;
}

.example .pagination > li > a,
.pagination > li > span {
  color: #2d619d;
  border: 1px solid #2d619d;
}

.example .pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus:hover {
  background-color: #2d619d !important;
  border-color: #2d619d !important;
  color: #fff !important;
  outline: none;
}

a#reload_href {
  margin: 0px 50px;
}

.hstack  button{
  white-space: nowrap;
}

.text-format {
  width: 100%;
  max-width: 360px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Statement_two {
  display: flex;
  justify-content: center;
  align-items: center;
}

.global_link .f-17 {
  /* font-size: 17px !important; */
  width: 400px !important;
}

input.upload_files {
  font-size: 15px;
  margin-left: 10px;
  margin-top: -5px;
}

.feedback_comment {
  padding: 10px;
  height: 100px;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  margin: 12px;
  background-color: #ededed;
}

span.upload {
  font-size: 20px;
  justify-content: center;
  display: flex;
  margin-top: 12px;
}

/* Start React Select Css */

.css-1s2u09g-control {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important;
  border: 1px solid #ced4da !important;
}

.fomr-group .css-1phadxg-control {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important;
}

.css-1pahdxg-control {
  height: 55px !important;
}

/* End React Select css */

.select-div2 {
  width: 100%;
}

.select-div2 .css-1s2u09g-control {
  height: 52px;
  margin-top: 10px;
  background: #f8fbff !important;
  border: 1px solid rgba(185, 185, 185, 0.5) !important;
  width: 100%;
}

.select-div2 .css-1pahdxg-control {
  margin-top: 10px;
}

.fline_img.upload-button2 {
  display: block;
}

.text_image {
  margin: 20px 0px;
}

input.form-control.div-upload {
  width: 115px;
  opacity: 0;
  position: absolute;
}

span.cancel-button {
  width: 100px;
  margin-left: 10px;
  color: #212529;
  font-size: 1rem;
  font-weight: 400;
  padding: 9px 10px;
  background: #233076;
  color: #fff;
}

span.cancel-button2 {
  width: 100px;
  margin-left: 10px;
  color: #212529;
  font-size: 1rem;
  font-weight: 400;
  padding: 9px 10px;
  background: red;
  color: #fff;
}

.user-image {
  width: 155px;
  object-fit: cover;
}

.helop_text2 {
  flex: 0 0 70% !important;
}

.helop_text2.button-edit {
  display: flex;
  justify-content: center !important;
  align-items: flex-start !important;
}

.helop_text2 button.edit.btn.btn-none {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-text {
  letter-spacing: 0.05px !important;
}

.ui.page.modals.dimmer.transition.visible.active {
  display: flex;
  z-index: 9999;
}

.ui.scrolling.modal.transition.visible.active.modal_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 530px;
  height: 90%;
}

.button-red {
  float: right;
  margin: 20px 40px;
}

.meettable {
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting-tb .nav-item .nav-link {
  padding: 10px 40px;
}

.image_sdg {
  width: 100%;
  max-width: 100px;
  height: 100%;
  min-height: 100px;
}

.image_sdg img {
  border-radius: 8px;
}

.pb3 .add_b {
  background: none !important;
  color: #05b49d !important;
  text-align: right !important;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-weight: 600;
}

.pb3 .add_b:hover {
  color: #05b49d !important;
}

/* .btn-primary {
  background: transparent !important;
  color: #005776 !important;
  padding: 0 8px !important;
} */

.add_b.btn-primary:focus {
  border: none !important;
  box-shadow: none !important;
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .color_div .div_1 {
    padding: 10px;
  }

  .color_div .div_2 {
    padding: 10px;
  }

  .color_div .div_3 {
    padding: 10px;
  }

  .revenue_head .button {
    margin-left: 30px;
    margin-top: 14px;
  }

  .heading .one {
    padding: 28px 0;
  }
}

@media (min-width: 990px) and (max-width: 1100px) {
  .div_3,
  .div_2,
  .div_1 {
    min-height: 295px;
  }

  .has-search .form-control-feedback {
    position: absolute;
    left: 300px;
  }

  .one {
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
    padding: 28px 45px;
  }

  /* .tabs-top ul {
    display: flex;
    width: 100%;
    flex: 0 0 30%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .tabs-top ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 25%;
    margin: 10px 0px;
  }

  .tabs-top ul li a {
    padding: 20px 18px;
  }

  .tabs-top ul li a.activee {
    padding: 20px 18px;
  } */

  .one {
    padding: 28px 0;
  }

  .stent_table {
    width: 30%;
  }

  .stent_table_one {
    width: 50%;
  }

  .stent_table_two {
    width: 20%;
  }

  .download_report {
    margin: 30px 0;
  }

  .download_report a {
    width: 100%;
    float: left;
    padding: 10px;
  }

  .tabs-top {
    margin: 0;
  }

  .FAverify .twoFA {
    width: 100%;
  }
}

span.price {
  font-weight: 600;
  color: #19619c;
}

.view_category svg {
  width: 18px;
  height: 18px;
  text-align: center;
  fill: #05b49d;
}

td.vew_d {
  text-align: center;
  vertical-align: middle;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view_c {
  text-align: center;
  cursor: pointer;
}

.view_c svg {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  fill: #05b49d;
  cursor: pointer;
}

.view_c i {
  color: #05b49d;
}

.fa_submit {
  display: flex;
  justify-content: center;
  align-items: center !important;
  max-width: 150px;
  margin: 0 auto !important;
  font-size: 16px;
  letter-spacing: 0.1em;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background: #1f9ed1 !important;
  color: #fff;
  font-size: 1em;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgb(112 102 224 / 0%) !important;
}

.swal2-styled.swal2-confirm:hover {
  background: linear-gradient(
    94.41deg,
    #233076 -47.54%,
    #2eb9e0 114.39%
  ) !important;
}

.swal-button {
  background: linear-gradient(
    94.47deg,
    #233076 1.14%,
    #19619c 114.41%
  ) !important;
}

.swal-button:hover {
  background: linear-gradient(
    94.41deg,
    #233076 -47.54%,
    #2eb9e0 114.39%
  ) !important;
}

.swal-text {
  font-weight: 900;
  font-size: 16px;
}

.add_subscription .modal-header {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 20px;
}

.button_companies {
  display: flex;
}

@media (min-width: 1024px) and (max-width: 1240px) {
  .button_companies {
    display: flex;
    flex-wrap: wrap;
  }

  .heading {
    display: block !important;
  }

  /* .inner_wraapper .tabs-top ul {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .inner_wraapper .tabs-top ul li {
    flex: 0 0 33%;
    margin: 10px 0px;
  } */

  .Statement .Statement_one {
    padding: 20px 0;
  }

  .Statement_two p.statement_p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 278px;
  }

  /* .steps-row .setting_admin ul li {
    margin: 0 48px;
  } */

  /* .inner_wraapper .tabs-top.setting_admin {
    margin: 0;
    flex-direction: column;
  }

  .inner_wraapper .tabs-top.setting_admin ul {
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0px 0 !important;
    padding: 0 !important;
    justify-content: center;
  }

  .inner_wraapper .tabs-top.setting_admin ul li {
    margin: 0 0;
    flex-direction: row;
    justify-content: center;
    flex: 0 0 30%;
  } */

  .four_div .four_text {
    height: 320px;
    padding: 20px 18px;
  }

  .four_div .four_text_one {
    height: 320px;
    padding: 20px 18px;
  }

  .four_div .four_text_two {
    height: 320px;
    padding: 20px 18px;
  }

  .four_div .four_text_there {
    height: 320px;
    padding: 20px 18px;
  }
}

/* @media (min-width: 1241px) and (max-width: 1442px) {
  .steps-row .setting_admin ul li {
    margin: 0 56px;
  }
} */

@media (min-width: 1024px) and (max-width: 1240px) {
  .of_Americat .margin_sustainable_10 {
    margin: 20px 0;
  }
}

.modal_body {
  width: 100%;
  padding: 38px;
}

.modal_body .header h4 {
  width: 100%;
  font-weight: 700;
  color: #233076;
  margin-bottom: 20px;
}

.modal_body .actions {
  margin-top: 20px;
}

.description input.form-control {
  height: 55px;
}

.feedback3 {
  height: 290px !important;
}

p.massage {
  font-weight: 600;
  margin: 40px 0;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.nav-link.home_drop {
  padding: 0 !important;
}

.attachment_with_icons {
  margin: 10px 0;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-end;
  width: 20%;
}

.attachment_with_icons span {
  display: flex;
}

.attachment_with_icons span .pdf {
  width: 100%;
  max-width: 22px;
}

.attachment_with_icons .icon_with_function {
  display: flex;
  gap: 10px;
}

.icon_with_function a {
  max-width: 50px;
  height: 50px;
  background: #1f9ed1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}

.form_audit_d {
  display: flex;
  width: 100%;
  align-items: center;
}

.icon_with_function a img {
  object-fit: cover;
}

.audit_enviornmental {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assign_compo {
  display: flex;
}

label.energy.mb-3.font-increase.form-label {
  width: 80%;
}

.attachment_with_icons {
  margin: 10px 0;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-end;
  width: 20%;
}

.attachment_with_icons span {
  display: flex;
}

.attachment_with_icons span .pdf {
  width: 100%;
  max-width: 22px;
}

.attachment_with_icons .icon_with_function {
  display: flex;
  gap: 10px;
}

.icon_with_function a {
  max-width: 33px;
  height: 33px;
  background: #1f9ed1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_audit_d {
  display: flex;
  width: 100%;
  align-items: center;
}

.audit_enviornmental {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_wrapper .my_profile_menus ul {
  display: flex;
}

.tabs-tops ul li {
  display: inline-block;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px 0 0;
}

.tabs-tops ul li a {
  border-radius: 50px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
}

.tabs-tops ul li a {
  color: #4f5367;
  text-decoration: none;
}

.tabs-tops ul li a.activee {
  background: #fff;
  padding: 20px 50px;
  border-radius: 50px;
  border: 1px solid #1f9ed1;
  color: #1f9ed1;
  text-decoration: none;
  text-align: center;
}

.main_wrapper .audit_tabs ul {
  justify-content: center;
}

.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}

.dropdown input#dropdownMenuLink {
  background: #ffff;
  border: 1px solid #ebefff;
  text-align: left;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown .shadow ul {
  display: block;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  height: 140px;
}

.dropdown .shadow ul li {
  text-decoration: none;
  display: block;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.check-form {
  display: flex !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  justify-content: space-between;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  cursor: pointer;
}

.check-form img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 10px;
}

.dropdown .dropdown-menu.border-0.shadow.show {
  padding: 10px;
}

.form-check-input:checked {
  background-color: #1f9ed1 !important;
  border-color: #1f9ed1 !important;
}

.input_one:checked {
  background: #05b49d;
  box-shadow: 0px 4px 12px rgb(5 180 157 / 30%);
  border-radius: 2px;
  border-color: #05b49d;
}

.Quantative_Sector {
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.Quantative_Sector_one {
  padding: 25px;
}

.form-check.form-check-inline {
  gap: 10px;
  display: flex;
}

.accordion-button {
  position: relative;
  display: inherit !important;
  width: 100%;
  padding: 11px 1rem !important;
  text-align: left;
  background-color: transparent !important;
  border: none;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #6a6f89 !important;
  transition: color 0s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0s ease !important;
}

.home_icon_img .accordion-button {
  margin-bottom: 20px;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0%) !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  display: flex;
  margin-top: -20px;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.access__section .accordion-header {
  background-color: #1f9ed11f;
  margin-bottom: 10px;
  padding: 0;
}

.audit_heading .accordion-button:hover {
  z-index: 2;
  background: linear-gradient(
    90deg,
    #1f9ed1 0%,
    rgb(0 149 209 / 45%) 100%
  ) !important;
  border-radius: 28px 0px 0px 25px !important;
  box-shadow: 0px 4px 10px rgb(25 97 156 / 0%);
  color: #fff !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: transparent !important;
  border: none !important;
  position: relative;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-body {
  padding: 0rem !important;
}

.accordion-body.text_body {
  padding: 0 !important;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.input-for-formula {
  border: 1px solid #e7f1ff;
  border-radius: 7px;
  padding: 6px;
}

.formula__suggesstions {
  background: gainsboro;
  height: calc(100vh - 490px);
  overflow: auto;
}

.view_formula_history:hover {
  background: gainsboro;
  border-radius: 4px;
}

.view_formula_history {
  padding: 5px;
  transition: 1s;
  cursor: pointer;
}

.formula__suggesstions > div {
  cursor: pointer;
  padding: 10px 10px 5px;
}

.formula__suggesstions > div:hover {
  background: #1780aa;
}

.error-message {
  color: red;
  text-shadow: 0 0 black;
}

select.select_one_all {
  padding: 5px;
  border: 1px solid #c7c8c9;
  border-radius: 4px;
}

.access__section .accordion-body {
  margin-bottom: 10px;
}

.border_box {
  border: 1px solid #eee;
  background: #f6f7fa;
  padding: 10px !important;
  border-radius: 7px;
}

.access__group__section {
  /* padding: 10px 10px 0;
  background: #f9fafc; */
  height: calc(100vh - 234px) !important;
  overflow-x: hidden;
}

/* .access__group__section .access__section {
  padding: 10px;
  border-bottom: 1px dashed #c4c4c4;
} */

.ist_component_for_billing {
  border: 1px solid #c9cace;
  padding: 10px;
  border-radius: 5px;
}

.ist_component_for_billing button {
  width: 100%;
  transition: 1s;
}

.ist_component_for_billing button:hover {
  background: #1f9ed1;
  color: #fff;
}

.pricing-table {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #1f9ed1;
  padding: 15px;
  transition: 0.3s;
  height: 100%;
  position: relative;
}

.pricing-table button {
  position: absolute;
  bottom: -15px;
  left: 40%;
}

/* .pricing-table:hover {
  box-shadow: 0px 1px 10px -4px rgb(31 158 209);
  background: #1f9ed1;
  color: #fff;
}

.pricing-table:hover span {
  color: #fff;
}

.pricing-table:hover h2 {
  color: #fff;
  border: 1px solid #fff;
} */

.pricing-table:hover .price-tag .after {
  color: #fff;
}

.Ans_section {
  border: 2px solid #ececec;
  padding: 10px 10px 0;
  border-radius: 5px;
}

.pricing-table p {
  background: #ff000012;
  padding: 5px;
  border-radius: 10px;
  font-size: 12px;
}

.pricing-table .pricing-label {
  border-radius: 2px;
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
}

.pricing-table h2 {
  font-size: 15px;
  font-weight: 600;
  margin: 0 auto;
  width: 150px;
  border: 1px solid #1f9ed1;
  text-align: center;
  border-radius: 7px;
  padding: 5px;
  color: #1f9ed1;
}

.pricing-table h5 {
  color: #b3b3b3;
  font-size: 14px;
  font-weight: 400;
}

.pricing-table .pricing-features .feature {
  font-size: 15px;
  margin: 0.5rem 0;
  font-weight: 600;
}

.pricing-table .price-tag {
  text-align: center;
  font-weight: 500;
}

.pricing-table .price-tag .symbol {
  font-size: 45px;
}

.pricing-table .price-tag .amount {
  letter-spacing: -2px;
  font-size: 50px;
}

.pricing-table .price-tag .after {
  color: #3b3b3b;
  font-weight: 500;
}

.pricing-table .price-button {
  display: block;
  color: #fff;
  margin-top: 2rem;
  padding: 0.75rem;
  border-radius: 2px;
  text-align: center;
  font-weight: 500;
  transition: 0.3s;
}

.pricing-table .price-button:hover {
  text-decoration: none;
}

.turquoise .pricing-label {
  background: #b9edee;
  color: #44cdd2;
}

.turquoise .price-tag {
  color: #1f9ed1;
}

.turquoise .price-button {
  background: #44cdd2;
}

.turquoise .price-button:hover {
  background: #2dbcc4;
}

.price-button-concel {
  background: #ff5e5e !important;
}

.price-button-concel:hover {
  background: #f23c3c !important;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-switch-handle {
  height: 15px !important;
  width: 15px !important;
}

.react-switch-bg {
  height: 17px !important;
  width: 45px !important;
}

.react-switch-bg > div {
  display: none;
}

.steps-row.setup-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1f9ed1;
  border-bottom: 10px solid #fff;
  overflow: auto;
}

.hol_rell {
  overflow: hidden;
}

.topic_Tab {
  display: none;
}

.tab-container {
  border-radius: 4px;
}

.tab-menu ul {
  margin: 0;
  padding: 0;
}

.tab-menu ul li {
  list-style-type: none;
  display: inline-block;
}

.tab-menu ul li > div {
  color: rgba(0, 0, 0, 0.4);
  padding: 7px 8px;
  cursor: pointer;
  background: #f2f2f2;
  border: 1px solid #00000017;
}

.tab-menu ul li > div.active-a {
  color: #1f9ed1;
  border-bottom: 1px solid #1f9ed1;
  font-weight: 600;
  background: #ebefff;
}

.tab {
  display: none;
}

.tab h2 {
  color: rgba(0, 0, 0, 0.7);
}

.tab p {
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
}

.tab-active {
  display: block;
}

.close_tab_cell i {
  color: #f05a00;
  margin-left: 10px;
  transition: 1s;
}
.close_tab_cell i:hover {
  transform: rotate(180deg) scale(1.5);
}

.add_framework_control i {
  font-size: 20px;
  padding: 5px;
  transition: 1s;
  border-radius: 4px;
}

.add_framework_control i:hover {
  background: #dcdcdc;
}

.auto_scroll_by_design {
  height: calc(100vh - 173px);
  overflow: auto;
}

.fianncial_year_table_hight {
  height: calc(100vh - 209px);
  overflow: auto;
}

.sticky_header {
  position: sticky;
  top: -1px;
}

.finacial_year_table {
  height: calc(100vh - 161px);
  overflow: auto;
}

.question_card_section {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 10px;
  border-radius: 4px;
}

.add_Question_section_panel {
  height: calc(100vh - 194px);
  overflow: auto;
  overflow-x: hidden;
}

.auto_scroll_by_brokerTable {
  height: calc(100vh - 157px);
  overflow: auto;
}

.pricing-features {
  height: 300px;
  overflow-x: hidden;
}


.billing_Page{
  height: calc(100vh - 141px);
  overflow: auto;
  overflow-x: hidden;
}

.question__add_plus i{
  color: #1f9ed1;
  padding: 5px;
  border-radius: 4px;
  transition: 1s;
  cursor: pointer;
  /* position: absolute;
  top: 8px;
  right: 40px;
  z-index: 9; */
}

.question__add_plus{
  border-bottom: 1px solid #dee2e6;
}

.table__sequence .question__add_plus:last-child{
  border-bottom: 0px solid;
}

.Financial__add_plus i{
  color: #1f9ed1;
  padding: 5px;
  border-radius: 4px;
  transition: 1s;
  cursor: pointer;
}

.question__add_plus i:hover,
.Financial__add_plus i:hover{
  background: #0dcaf0;
  color: #000;
}

.spinner-border {
  --bs-spinner-width: 1.5rem !important;
  --bs-spinner-height: 1.5rem !important;
  color: #fff;
}

.table-bordered>:not(caption)>:hover,
.table-striped>tbody>tr:nth-of-type(odd)>*:hover td{
  background: #1f9ed1;
  color: #fff !important;
}

.table-bordered>:not(caption)>:hover i{
  color: #fff;
}

.country___level{
  background: #ccc;
}