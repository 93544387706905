table .table-tag{
    color: #233076;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 14px;
}


.subscription_plan {
    display: flex;
    font-weight: 700;
    gap: 10px;
    align-items: center;
}

.address h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #1f9ed1;
    margin-bottom: 22px;
    margin-top: 0;
}
.address p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #4F5367;
    margin-bottom: 15px;
}
.address .amount {
    font-weight: 600;
    font-size: 42px;
}
.table_footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
}
label{
    font-size: 14px !important;
}
.footer-lable label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #4F5367;
    margin-bottom: 22px;
    margin-top: 0;
}
.footer-lable p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #4F5367;
    margin-bottom: 22px;
    margin-top: 0;
}
.red{
    color: #E70000 !important;
}
.green{
    color: #05B49D !important;
}

.orange{
    color: #c97d1c !important;
}

.black{
    color: #4F5367 !important;
}

.bold{
    font-weight: bold;
}

a.non_underline_link{
    text-decoration: none;
}

.biling-menu{
    appearance: menulist-button !important;
    -webkit-appearance: menulist-button !important;
}
.duja-input {
    border: 1px solid #05B49D !important;
}
.hara-rang {
    color: #05B49D;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
.hara-rang:hover{
    color: #05B49D;
}

.removeMeta i {
    border: 1px solid #eee;
    padding: 19px;
    height: 52px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 5px;
    color: #b9b9b9;
    cursor: pointer;
}
.page-wid {
    background: #b9b9b9 !important;
    margin-top: 6px;
    border: none !important;
}

.container__color{
    background-color: #fff;
    box-shadow: 0px 0px 3px #aaaaaa;
    margin:0px 5px 40px 5px !important;
    padding: 20px;
    border-radius: 5px;
}
/* table td,th{
 padding:10px;
}

.table_f table tbody tr td {
    background: none;
    height: 66px;
    vertical-align: center;
    padding: 10px;
    vertical-align: middle;
    padding: 20px;
} */