.access-denied-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .access-denied-title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .access-denied-message {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }