#chartdiv {
  width: 100%;
  height: 500px;
}
.response {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.response h4 {
  color: #2b377b;
  font-weight: 900;
  font-size: 30px;
}

.Data {
  position: absolute;
  content: "";
  left: -180px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
}
.env-l {
  width: 240px;
  min-height: 150px;
  border: 1px solid #ebefff;
  padding: 20px;
}
