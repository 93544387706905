.demoBar {
  display: flex;
  gap: 10px;
  // padding: 15px;

  //   width: 100%;
  //   border-bottom: 1px solid #e5e5e5;
  //   padding: 0px;
  //   height: 31px;
}

.previewFB {
  font-size: 12px!important;
}
