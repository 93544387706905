.dropdown_menu a i {
  color: #fff;
  background: #1f9ed1;
  padding: 8px 0;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
}

.dropdown_menu span {
  margin: 0 10px;
}

.d-flex .background2 {
  background: #ffffff;
  padding-bottom: inherit;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1045;
}

li.nav-item.activities-b {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.activation-notification {
  position: relative;
}

.activation-notification span.badge {
  position: absolute;
  top: -10px;
  z-index: 3;
  background: #1f9ed1;
  right: -8px;
  border-radius: 50%;
}

.activation-notification a {
  width: 30px;
  height: 30px;
  background: #fff;
  padding: 15px;
  position: relative;
  color: #1f9ed1;
  border: 1px solid;
  border-radius: 4px;
}


.activation-notification a i {
  position: absolute;
  top: 9px;
  left: 9px;
}

.notification_section h5 {
  font-size: 15px;
  padding: 5px 10px;
  margin: 0;
  font-weight: bold;
}

.notification_section a {
  font-size: 12px;
  padding: 5px 15px;
  margin: 0;
}


.notification_section i {
  font-size: 12px;
  padding: 5px 15px;
  margin: 0;
  transform: rotate(315deg);
}

.notification_body {
  padding: 15px 15px 0;
  height: 450px;
  overflow: auto;
}

.notification_body i {
  font-size: 25px;
  padding: 10px 0;
  transform: rotate(0deg) !important;
}

.notification_body h4 {
  font-size: 16px;
  margin: 0;
  font-weight: bold;
}

.notification_body p {
  font-size: 12px;
  margin: 0;
}