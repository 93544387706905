.download_report .download_css {
  border: 1px solid #233076;
  background: #fff;
  opacity: 0.4;
  font-weight: 700;
}

.dataValue {
  border: 1px solid #00000017;
  padding: 12px;
  border-radius: 5px;
}

.setup-content .sve_next .downloadcss {
  border-radius: 3px !important;
  color: #05b49d !important;
  border: 2px solid #05b49d !important;
}
.w-90 {
  /* width: 200px; */
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 20px;
}
.w-20 {
  width: 100px;
}
.manage-detail button {
  text-decoration: none;
  letter-spacing: 3px;
  display: flex;
  white-space: nowrap;
}


.variants {
  display: flex;
  margin: 16px;
}

.variants > div {
  margin-right: 5px;
}

.variants > div:last-of-type {
  margin-right: 0;
}

.file {
  position: relative;
  display: flex;
}

.file > input[type='file'] {
  display: none
}

.file > label {
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 29%);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > label:hover {
  border-color: hsl(0, 0%, 21%);
}

.file > label:active {
  background-color: hsl(0, 0%, 96%);
}

.file > label > i {
  padding-right: 5px;
}

.file--upload > label {
  color: #1c5593;
  border-color: #1c5593;
}

.file--upload > label:hover {
  border-color: hsl(204, 86%, 53%);
  background-color: hsl(204, 86%, 96%);
}

.file--upload > label:active {
  background-color: hsl(204, 86%, 91%);
}

a.outputFile {
  border: 1px solid #05b49d;
  border-radius: 5px;
  padding: 15px;
  margin-left: 20px;
}

.hideBox {
  display: none;
}