.text_c {
  width: 27%;
  padding: 13px 11px;
}
.color_div_key {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 0 0 10px 10px;
  height: 303px;
  overflow: auto;
}
.card .card-block .text-muted {
  color: #fff !important;
}
.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 1px dashed #d1d8f3;
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: 30px auto 0px;
}

/* .image-dropping,
  .image-upload-wrap:hover {
    background-color: #1FB264;
    border: 4px dashed #ffffff;
  }  */
.drag-text {
  padding: 20px;
}
.drag-text h3 {
  font-size: 16px;
  text-align: center;
  padding: 10px;
}
.drag-text i {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #6A6F89;
}
.text-outside p {
  text-align: center;
  padding: 10px;
}
.image-upload-wrap a {
  text-decoration: none;
  color: #636464;
}
.image-upload-wrap a:hover {
  color: #636464;
}
/* .div_scroll {
  height: auto;
  overflow-y: scroll;
  overflow: auto;
} */
/* .div_scrolls {
  height: 38vh !important;
} */
.div_scroll li a {
  width: 100%;
  float: left;
  padding: 5px;
  color: #233076;
}
.div_scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.div_scroll::-webkit-scrollbar:vertical {
  width: 7px;
}

.div_scroll::-webkit-scrollbar:horizontal {
  height: 11px;
}

.div_scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #edeff6; /* should match background, can't be transparent */
  /* background-color: rgba(0, 0, 0, .5);*/
  background-color: #edeff6;
}
.color_div._q {
  padding: 0;
  height: 350px;
  overflow: auto;
}
.color_div._q::-webkit-scrollbar {
  -webkit-appearance: none;
}

.color_div._q::-webkit-scrollbar:vertical {
  width: 7px;
}

.color_div._q::-webkit-scrollbar:horizontal {
  height: 11px;
}

.color_div._q::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #edeff6; /* should match background, can't be transparent */
  /* background-color: rgba(0, 0, 0, .5);*/
  background-color: #c3c3c3;
}

.main_text {
  display: flex;
  flex-direction: column;
}

.hd_process {
  display: flex;
  justify-content: center;
}
.hd_process img {
  position: absolute;
  top: 19px;
  bottom: 0;
  max-width: 460px;
}

.pie-wrapper:nth-child(3n + 1) {
  clear: both;
}
.pie-wrapper {
  width: 100%;
  max-width: 19em;
  height: 19em;
  float: left;
  margin: 40px 15px;
  position: relative;
}
.pie-wrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 1em, 1em, 0.5em);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper.style-2 .label {
  background: #fff;
  color: #232e76;
  display: flex;
  flex-direction: column;
  border: 11px solid #41c7ed;
}
.pie-wrapper .label {
  border-radius: 50%;
  bottom: 0;
  cursor: default;
  display: block;
  font-size: 3em;
  left: 0;
  line-height: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  font-weight: 600;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pie-wrapper.style-2 .label .smaller {
  color: #bdc3c7;
}

.pie-wrapper .label .smaller {
  color: #bdc3c7;
  font-size: 0.45em;
  padding-bottom: 20px;
  vertical-align: super;
}

.pie-wrapper.progress-95 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 1em, 1em, 0.5em);
  left: 0;
  position: absolute;
  top: 0;
}

.pie-wrapper.progress-95 .pie .left-side {
  transform: rotate(342deg);
}

.pie-wrapper.progress-95 .pie .half-circle {
  border-color: #e74c3c;
}

.pie-wrapper .pie .half-circle {
  height: 100%;
  width: 100%;
  border: 0.1em solid #3498db;
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  left: 0;
  position: absolute;
  top: 0;
}

.pie-wrapper .shadow {
  height: 100%;
  width: 100%;
  border: 1.1em solid #41c7ed;
  border-radius: 50%;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/* .pie:before {
    position: absolute;
    content: '\f0d7';
    font-family: Font Awesome 5 Brands;
    display: inline-block;
} */

span.exc {
  font-size: 0.5em;
  color: #3db1db;
}

.font {
  position: absolute;
  top: 70px;
  right: -12px;
  transform: rotate(262deg);
}

.font i {
  font-size: 54px;
  color: #41c7ed;
}

.enviornment_consideration li {
  width: 50%;
  text-align: center;
}