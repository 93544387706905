.form-builder-preview {
  // width: 100%;
  // max-height: calc(100vh - 128px);
  // max-height: calc(100vh - 202px);
  width: 70%;
  margin: auto;
  max-height: calc(100vh - 178px);
  border: 1px solid #ced4da;
  border-radius: 10px;
  margin-top: 6px;
  padding: 15px;
  overflow: auto;

  div {
    width: 100%;
  }
}

// REQUIRED BADGE STYLE........................

.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item.SortableItem
  .form-group
  .label-required {
  margin-left: 5px;
  margin-bottom: 2px;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// ............................................

input.btn.btn-big {
  background: #103c5e;
  color: white;
  border: 1px solid transparent;
  width: 130px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &:hover {
    background: transparent;
    border: 1px solid #103c5e;
    color: #103c5e;
  }
}

.builderTabs {
  height: 25px;
  padding: 0px 10px 0px 10px;

  .builderTabItems {
    padding: 0px;
    margin-left: 10px;
    font-size: 14px;
  }

  .builderTabItems .nav-link {
    color: gray;
  }

  .builderTabItems .nav-link .active {
    color: #103c5e;
  }
}

.react-form-builder-toolbar,
.is-isolated {
  li {
    font-size: small;
  }

  i {
    color: #103c5e;
    font-size: small;
  }
}

.react-form-builder-preview input[type="checkbox"],
.react-form-builder-preview input[type="radio"],
.react-form-builder-form input[type="radio"],
input[type="checkbox"] {
  margin-right: 5px;
}

.react-form-builder {
  .react-form-builder-preview {
    // position: relative;
    // width: 70%;
    // border: 1px solid #ddd;
    margin-left: 8px;
    // background: #fafafa;
    padding: 10px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    // min-height: 750px;
    // min-height: 70vh;
    height: calc(100vh - 182px);
    width: 100%;

    .preview-page-break {
      padding: 5px 0;
      border-top: 2px dotted #ccc;
      border-bottom: 2px dotted #ccc;
      background: #eee;
      text-align: center;
      width: 100%;
      font-weight: bold;
      margin: 5px 0;
      background: repeating-linear-gradient(
        45deg,
        #fff,
        #fff 10px,
        #eee 10px,
        #eee 20px
      );
    }

    label {
      font-weight: normal;
    }

    .bold {
      font-weight: bold;
    }

    .italic {
      font-style: italic;
    }

    .no-image {
      background: #eee;
      width: 100px;
      height: 100px;
      border: 1px solid #ddd;
      text-align: center;
      padding-top: 35px;
    }

    .option-inline {
      display: inline-block !important;
      margin-right: 10px !important;
    }

    .form-label {
      display: block !important;
    }

    .edit-form {
      position: fixed;
      background: #fafafa;
      padding: 30px;
      border-left: 0;
      box-shadow: none;
      top: 0;
      left: -1000px;
      height: 100%;
      // width: 0;
      width: 715px;
      transition: 0.5s;
      overflow-y: auto;

      h4,
      .dismiss-edit {
        margin-bottom: 30px;
      }

      .dismiss-edit {
        cursor: pointer;
        margin-top: 10px;
        font-size: 125%;
      }

      textarea {
        width: 100%;
      }

      .form-group {
        .form-group-range {
          label {
            display: block;
          }

          input {
            display: inline-block;
            width: 21%;
            margin-left: 0;
            &:last-of-type {
              width: 78%;
              margin-left: 1%;
            }

            &:first-of-type {
              width: 21%;
              margin-left: 0;
            }
          }
        }
      }

      .dynamic-option-list {
        margin-bottom: 15px;
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            margin-top: 10px;
            input {
              &.form-control {
                width: 84.26%;
                float: left;
                &[type="checkbox"] {
                  border: none;
                  box-shadow: none;
                  margin-top: 0;
                }
              }

              margin-right: 10px;
            }

            button {
              float: left;
              margin-right: 5px;
            }

            .dynamic-options-actions-buttons {
              margin-left: 20px;
            }
          }
        }
      }
    }

    .Sortable {
      transition: opacity 0.25s ease-in;
      position: relative;
      cursor: move;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .rfb-item {
        padding: 10px 20px;
        &.SortableItem {
          position: relative;
          .form-group {
            pointer-events: none;
            label,
            select,
            input,
            a {
              cursor: move;
            }

            input[type="date"] {
              height: 42px;
            }

            .react-star-rating {
              display: block;
            }

            .checkbox-label,
            .radio-label {
              font-weight: normal;
              display: block;
            }

            .label-required {
              margin-left: 5px;
            }
          }

          &.is-dragging {
            position: absolute;
            z-index: 1688;
            border: 2px #ccc dashed;
            background: #fff;
            cursor: move;
            padding: 10px 20px;
          }
        }

        &.is-placeholder {
          display: block;
          z-index: auto;
          opacity: 0.4;
          border: 2px #ccc dashed;
        }

        .toolbar-header {
          opacity: 0; // visible
          z-index: 100;
          position: relative;
          .label {
            position: absolute;
            left: 20px;
          }

          .toolbar-header-buttons {
            position: absolute;
            right: 20px;
            margin-top: -20px;
          }
        }

        &:hover {
          box-shadow: inset 0 0 5px #ddd;
          background: #fff;
          select,
          input {
            cursor: move;
            pointer-events: none;
          }

          .toolbar-header {
            opacity: 1;
          }
        }
      }
    }

    &.is-editing {
      // .Sortable {
      //   opacity: .5;
      //   transition: opacity .25s ease-in;
      // }

      .edit-form {
        z-index: 2000;
        border-right: 1px solid #ddd;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);

        height: calc(100vh - 109px);
        margin-top: 70px;
        // width: 80%;
        left: 0;
        transition: 0.5s;
      }
    }
  }

  .react-form-builder-toolbar {
    width: 100%;
    background: #fff;
    margin-top: -30px;

    h4 {
      // margin-top: 0;
      text-align: center;
      position: sticky;
      top: 0px;
      background: white;
    }

    .form-group {
      padding: 10px;
      border: 1px dashed #ddd;
    }

    ul {
      padding: 0;
      li {
        cursor: pointer;
        list-style: none;
        margin: 5px;
        padding: 10px;
        border: 1px dashed #ddd;
        i {
          margin: 0 15px 0 10px;
        }
      }
    }
  }
}

.image-upload-container {
  position: relative;
  .image-upload {
    position: relative;
    opacity: 0;
    z-index: 2;
    height: 50px;
  }

  .image-upload-control {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
}

.image-upload-preview {
  border: 4px solid #fff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
}

.file-upload-preview {
  border: 4px solid #fff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.btn-image-clear {
  margin: 15px 0;
  padding: 15px;
}

.btn-file-upload-clear {
  margin: auto;
  padding: 10px;
}

@media (max-width: $screen-sm-min) {
  .clearfix {
    display: flex !important;
    width: 100%;

    .cfChild1 {
      width: 60%;
    }

    .cfChild2 {
      width: 40%;
    }
  }

  .react-form-builder {
    position: relative;

    .react-form-builder-preview {
      width: 100%;

      .edit-form {
        width: 100%;
        overflow: scroll;

        .btn {
          margin-top: 10px;
        }

        .dynamic-option-list ul li input.form-control {
          width: 100%;
        }
      }

      .Sortable .rfb-item .toolbar-header .toolbar-header-buttons {
        right: -15px;
        .btn {
          margin-right: 5px;
          border-radius: 50%;
          padding: 7px 0px 0 3px;
          width: 35px;
          height: 35px;
        }
      }
    }

    .react-form-builder-toolbar {
      h4 {
        padding: 10px;
        // background: #7a0019;
        color: black;
      }

      width: 100%;
      // position: fixed;
      z-index: 900;
      bottom: -320px;

      -webkit-transition: transform 0.5s;
      transition: transform 0.5s;

      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);

      right: 0;
      border-top: 2px solid #ccc;

      // ul {
      //   // height: 300px;
      //   // overflow-x: scroll;
      // }
    }

    .active-toolbar {
      -webkit-transform: translate(0, -300px);
      transform: translate(0, -300px);
    }
  }

  .m-signature-pad {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    min-width: 250px;
    min-height: 140px;
    margin: 0;
  }
}

.visible_marks {
  display: block;
  width: 100%;
  padding: 0 4px;
  label {
    text-align: center;
    display: inline-block;
    padding: 0;
    margin: 0;
    font-weight: normal;
    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }
  }
}

.m-signature-pad {
  font-size: 10px;
  width: auto;

  .m-signature-pad--body {
    width: auto;
    height: 200px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    height: 200px;
    border-radius: 4px;

    canvas {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
    }
  }

  .m-signature-pad--footer {
    position: relative;
    margin-top: 10px;
  }
}

.form-place-holder {
  margin: 10px;
  border: 2px dashed #bbb;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  font: 20pt bold, "Vollkorn";
  color: #bbb;

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: calc(100vh - 222px);
}

.form-place-holder-hidden {
  min-height: 50px;
}

.col-md-3.react-form-builder-toolbar.float-right,
.Sortable {
  overflow: auto;
  max-height: calc(100vh - 206px);
}
